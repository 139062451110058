import React from "react";

import Text from "../../components/lib/Text";
import Stack from "../../components/lib/Stack";
import Grid from "../../components/lib/Grid";
import Box from "../../components/lib/Box";

import { DraggableLayer, DroppableLayer, useDragAndDropState } from "./DnD";
import { LayerisOver, LayerisDrop } from "./SearchAllocatorDnD";
import { styled } from "../../stitches.config";
import { Card } from "../../components/lib/Card";

import type { SearchAllocatorUserDataObject } from "./types";

function generateAvatarName(firstName: string, lastName: string, username: string) {
  let avatarName = "";

  if (firstName && firstName[0]) {
    avatarName += firstName[0];
  }

  if (lastName && lastName[0]) {
    avatarName += lastName[0];
  }

  if (avatarName.length === 0) {
    avatarName = username.substring(0, 2);
  }

  return avatarName.replace(/null/g, "");
}

function getStatus(isActive: boolean, isPSPAdmin: boolean) {
  let status = null;

  if (isActive === false) {
    status = "Inactive";
  }

  if (isActive && isPSPAdmin) {
    status = "Admin";
  }

  return status;
}

function isPSPAdmin(user: SearchAllocatorUserDataObject) {
  return user.legacyRoles.some(
    (role) => role.isActive === true && role.name === "Per Search Pricing Admin"
  );
}

const AvatarContainer = styled(Stack, {
  backgroundColor: "#814374",
  color: "$white",
  borderRadius: "$full",
  width: "60px",
  height: "60px",
  alignContent: "center",
  justifyContent: "center",
});

const CountContainer = styled(Stack, {
  gap: "$0",
  alignItems: "flex-start",
  borderRight: "1px solid $secondaryLightest",
  "&:last-child": {
    borderBottom: "none",
  },
  padding: "$2",
});

type Props = {
  user: SearchAllocatorUserDataObject;
};

export default function User(props: Props) {
  const { user } = props;

  const {
    isOver,
    setIsOver,
    isDrop,
    setIsDrop,
    searchAllocatorInput,
    setSearchAllocatorInput,
  } = useDragAndDropState();

  const {
    username,
    firstName,
    lastName,
    isActive,
    resourceAllocationId,
    searchesAllocated,
    searchesRemaining,
    searchesUsed,
  } = user;

  if (resourceAllocationId === null) {
    return <></>;
  }

  const allocatedColor = searchesAllocated === 0 ? "#e74c3c" : "#c2d646";
  const avatarName = generateAvatarName(firstName, lastName, username);
  const status = getStatus(isActive, isPSPAdmin(user));

  return (
    <DraggableLayer
      id={resourceAllocationId}
      disabled={isDrop}
      searchesRemaining={searchesRemaining}
    >
      <DroppableLayer
        id={resourceAllocationId}
        onOver={setIsOver}
        onDrop={setIsDrop}
        onSearchAllocatorInput={setSearchAllocatorInput}
      >
        <Card
          fill
          css={{
            borderLeft: `3px solid ${allocatedColor}`,
          }}
        >
          {isOver && <LayerisOver isAdd />}
          {isDrop && (
            <LayerisDrop
              isAdd
              searchAllocatorInput={searchAllocatorInput}
              onCancel={() => setIsDrop(false)}
            />
          )}
          <Grid
            css={{
              gridTemplateColumns: "$20 auto",
              gap: 0,
            }}
          >
            <Stack css={{ gap: "$2", alignItems: "center", justifyContent: "center" }}>
              <AvatarContainer>
                <Text css={{ textTransform: "uppercase", fontSize: "$3xl" }}>
                  {avatarName}
                </Text>
              </AvatarContainer>
              <Text thin>{status}</Text>
            </Stack>

            <Stack
              nogap
              css={{
                alignItems: "flex-start",
                borderLeft: "1px solid $secondaryLightest",
              }}
            >
              <Box css={{ padding: "$2 $2" }}>
                <Text size="lg">
                  {firstName} {lastName} <br />({username})
                </Text>
              </Box>
              <Stack
                nogap
                fill
                css={{
                  flexDirection: "row",
                  borderTop: "1px solid $secondaryLightest",
                  justifyContent: "space-between",
                }}
              >
                <CountContainer>
                  <Text css={{ fontSize: "$3xl" }}>{searchesUsed}</Text>
                  <Text thin css={{ textTransform: "uppercase" }}>
                    used
                  </Text>
                </CountContainer>
                <CountContainer>
                  <Text css={{ fontSize: "$3xl" }}>{searchesRemaining}</Text>
                  <Text thin css={{ textTransform: "uppercase" }}>
                    remaining
                  </Text>
                </CountContainer>
                <CountContainer>
                  <Text css={{ fontSize: "$3xl" }}>{searchesAllocated}</Text>
                  <Text thin css={{ textTransform: "uppercase" }}>
                    allocated
                  </Text>
                </CountContainer>
              </Stack>
            </Stack>
          </Grid>
        </Card>
      </DroppableLayer>
    </DraggableLayer>
  );
}
