// @flow strict
import * as React from "react";

type Props = {
  url: string,
  sessionId: string,
};

const IFrame = (props: Props) => {
  const url = new URL(props.url);
  url.searchParams.set("useV7", "1"); // Forces Reactr to only use v7 auth checks
  url.searchParams.set("x-session-id", props.sessionId);
  return (
    <iframe
      title="legacy-iframe"
      src={url.toString()}
      frameBorder="0"
      style={{
        border: 0,
        width: "calc(100% - 30px)",
        height: "calc(100vh - 50px)",
      }}
    />
  );
};

export default IFrame;
