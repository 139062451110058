import { List, Map, Set, OrderedMap } from "immutable";

import {
  ImmutableArbitraryMap,
  ImmutableList,
  ImmutableMap,
  ImmutableOrderedMap,
  ImmutableSet,
} from "../types/immutable";

export const emptyList = List() as unknown as ImmutableList<any>;
export const emptySet = Set() as unknown as ImmutableSet<any>;
export const emptyMap = Map() as unknown as
  | ImmutableArbitraryMap<string, any>
  | ImmutableMap<any>;
export const emptyOrderedMap = OrderedMap() as unknown as ImmutableOrderedMap<
  number | string,
  any
>;

export const djangoOrderingKey = "ordering";
export const djangoPaginationKey = "page";
export const djangoPaginationSizeKey = "page_size";
export const djangoSearchKey = "search";

export const supportEmail = "PayIntelHelp@magnitglobal.com";
