import type { SearchAllocatorUserDataMap } from "./types";

// types

export type TargetFilterTypeLitteral = "active" | "inactive" | "all";

export type SortByFilterTypeLitteral =
  | "searchesAllocated"
  | "searchesRemainingAsc"
  | "searchesRemainingDesc"
  | "searchesUsed"
  | "usernameAZ"
  | "usernameZA";

export type StateFilterType = {
  filterBy: string;
  targetFilter: TargetFilterTypeLitteral;
  sortBy: SortByFilterTypeLitteral;
};

// handler filters

export function nameFilter(user: SearchAllocatorUserDataMap, filterBy: string) {
  const firstName = user.get("firstName") || "";
  const lastName = user.get("lastName") || "";
  const name = String(firstName) + String(lastName);
  return typeof filterBy === "string" && name.toLowerCase().includes(filterBy);
}

export function statusFilter(
  user: SearchAllocatorUserDataMap,
  filterBy: TargetFilterTypeLitteral
) {
  switch (filterBy) {
    case "active":
      return user.get("isActive") === true;
    case "inactive":
      return user.get("isActive") === false;
    case "all":
      return true;
    default:
      return false;
  }
}

export function sortFilter(sortBy: SortByFilterTypeLitteral) {
  const sortFunctions: Record<
    SortByFilterTypeLitteral,
    (a: SearchAllocatorUserDataMap, b: SearchAllocatorUserDataMap) => number
  > = {
    searchesAllocated: (a, b) => b.get("searchesAllocated") - a.get("searchesAllocated"),

    searchesRemainingDesc: (a, b) =>
      b.get("searchesRemaining") - a.get("searchesRemaining"),

    searchesRemainingAsc: (a, b) =>
      a.get("searchesRemaining") - b.get("searchesRemaining"),

    searchesUsed: (a, b) => b.get("searchesUsed") - a.get("searchesUsed"),

    usernameAZ: (a, b) =>
      a.get("username").toLowerCase().localeCompare(b.get("username").toLowerCase()),

    usernameZA: (a, b) =>
      b.get("username").toLowerCase().localeCompare(a.get("username").toLowerCase()),
  };

  const sortFunction = sortFunctions[sortBy];
  if (!sortFunction) {
    throw new Error("Invalid sort key");
  }
  return sortFunction;
}

// values

export const defaultFilters: StateFilterType = {
  filterBy: "",
  targetFilter: "active",
  sortBy: "searchesAllocated",
};
