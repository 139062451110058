import { fromJS } from "immutable";

import type {
  SearchAllocatorClientDataMap,
  SearchAllocatorUserDataObject,
  SearchAllocatorUserDataMap,
  SearchAllocatorResourceClientResponse,
} from "./types";

// users converts

const userToImmutableMap = (
  item: SearchAllocatorUserDataObject
): SearchAllocatorUserDataMap => {
  let resourceAllocationId: string | null = null;
  let searchesAllocated = 0;
  let searchesRemaining = 0;
  let searchesUsed = 0;

  if (item.resourceAllocations.length > 0) {
    resourceAllocationId = item.resourceAllocations[0].id;
    searchesAllocated = item.resourceAllocations[0].allocated;
    searchesRemaining = item.resourceAllocations[0].balance;
    searchesUsed = item.resourceAllocations[0].searchesUsed;
  }

  item["resourceAllocationId"] = resourceAllocationId;
  item["searchesAllocated"] = searchesAllocated;
  item["searchesRemaining"] = searchesRemaining;
  item["searchesUsed"] = searchesUsed;

  return fromJS(item);
};

// search allocator converters

export const searchAllocatorToImmutableMap = (
  item: SearchAllocatorResourceClientResponse
): SearchAllocatorClientDataMap => {
  const resourceAllocationId = item.resourceAllocations[0].id;
  const resourceAllocation = item.resourceAllocations[0].balance;
  const resourcePrice = item.resourcePrices[0].price;
  const resourceUsed = item.resourcesUsed[0].used;
  const tier = item.tiers[0].amount;
  const users = item["users"].map(userToImmutableMap);

  return fromJS({
    resourceAllocationId,
    resourceAllocation,
    resourcePrice,
    resourceUsed,
    tier,
    users,
  });
};
