// @flow strict
import IFrame from "./IFrame";
import * as React from "react";
import SessionInfo from "../models/SessionInfo";

type Props = {
  route: {
    serviceURL: string,
  },
  sessionInfo: SessionInfo,
};

const SearchAllocatorIFrame = (props: Props) => {
  if (!props.sessionInfo.legacySession) {
    return <div>Unable to load Search Allocator because the session ID is missing.</div>;
  }
  return (
    <IFrame
      url={`${props.route.serviceURL}embed/search-allocator/`}
      sessionId={props.sessionInfo?.legacySession}
    />
  );
};

export default SearchAllocatorIFrame;
