import React, { useState } from "react";
import { DndContext } from "@dnd-kit/core";

import Stack from "../../components/lib/Stack";
import Divider from "../../components/lib/Divider";
import UserList from "./UserList";
import Header from "./Header";
import { TickerPageLoader } from "../../components/lib/TickerLoader";

import { defaultFilters, nameFilter, statusFilter, sortFilter } from "./filters";
import { useSearchAllocatorData } from "./hooks";

import type { StateFilterType } from "./filters";

export default function SearchAllocatorNew() {
  const [filters, setFilters] = useState<StateFilterType>(defaultFilters);

  const { data: searchAllocatorData, loading } = useSearchAllocatorData();

  if (loading || searchAllocatorData === null) {
    return <TickerPageLoader />;
  }

  // data

  const resourceAllocationId = searchAllocatorData.get("resourceAllocationId");
  const searchesUsed = searchAllocatorData.get("resourceUsed");
  const searchCount = searchAllocatorData.get("resourceAllocation");
  const searchPrice = searchAllocatorData.get("resourcePrice");
  const searchTier = searchAllocatorData.get("tier");

  // user filters

  const users = searchAllocatorData
    .get("users")
    .filter((user) => statusFilter(user, filters.targetFilter))
    .filter((user) => (filters.filterBy ? nameFilter(user, filters.filterBy) : true))
    .sort(sortFilter(filters.sortBy));

  return (
    <DndContext>
      <Stack>
        <Header
          resourceAllocationId={resourceAllocationId}
          searchPrice={searchPrice}
          searchTier={searchTier}
          searchesUsed={searchesUsed}
          searchCount={searchCount}
          setFilters={setFilters}
        />
        <Divider />
        <UserList users={users} />
      </Stack>
    </DndContext>
  );
}
