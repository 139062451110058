import React from "react";

import Grid from "../../components/lib/Grid";
import User from "./User";

import type { SearchAllocatorUserDataMap } from "./types";

type Props = {
  users: SearchAllocatorUserDataMap[];
};

export default function (props: Props) {
  const { users } = props;

  const usersRender = users.map((user: SearchAllocatorUserDataMap) => (
    <User key={user.get("id")} user={user.toJS()} />
  ));

  return (
    <Grid
      fill
      css={{
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        "@md": {
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        },
        "@lg": {
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        },
        color: "$secondary",
        gap: "$5",
        justifyContent: "center",
        justifyItems: "stretch",
        padding: "$1",
      }}
    >
      {usersRender}
    </Grid>
  );
}
